/**
 * Extract PDF Blob Data to be Downloaded via BLOB URL
 * @param {Blob Data} data
 * @param {object} disposition
 * @returns {HTMLAnchorElement}
 */
export const downloadPDF = (data, disposition, isAutoOpen) => {
  return new Promise((resolve, reject) => {
    let filename = 'downloaded.pdf';

    // Extract filename from 'Content-Disposition' header (if available)
    if (disposition && disposition.includes('attachment')) {
      let filenameRegex = /fileName[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = new Blob([data], { type: 'application/pdf' });
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const pdfBase64 = reader.result.split(',')[1]; // Extract Base64 string
      // Auto open PDF in a new tab if enabled
      if (isAutoOpen) {
        resolve(pdfBase64);
      } else {
        resolve('');
      }

      // Create download link
      const link = document.createElement('a');
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    reader.onerror = error => reject(error);
  });
};
