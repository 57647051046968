import { getCardBackgroundDashboard } from 'helpers/getAssetsByPartner';
import { getPartnerId } from 'helpers/getPartnerId';

const imgUrl = getCardBackgroundDashboard(getPartnerId);

export default theme => ({
  rootCicil: {
    padding: theme.spacing(2),
    borderRadius: '12px 12px 0px 0px',
    boxShadow: '2px 2px 12px rgb(7 83 154 / 15%)',
    border: 'none'
  },
  rootKasbon: {
    padding: theme.spacing(2),
    borderRadius: '12px',
    boxShadow: '2px 2px 12px rgb(7 83 154 / 15%)',
    border: 'none'
  },
  rootWoCard: {
    padding: theme.spacing(2),
    borderRadius: '12px',
    boxShadow: '2px 2px 12px rgb(7 83 154 / 15%)',
    border: 'none'
  },
  root2: {
    padding: theme.spacing(2),
    borderRadius: '0px 0px 12px 12px',
    boxShadow: '2px 2px 12px rgb(7 83 154 / 15%)',
    border: 'none'
  },
  rootPaymentConfirm: {
    marginTop: theme.spacing(2.2),
    borderRadius: '0px 0px 12px 12px',
    border: 'none'
  },
  isCicil: {
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover'
  },
  greyText: {
    color: theme.palette.common.white,
    fontSize: '12px',
    weight: 400,
    lineHeight: '18px'
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItem: 'center'
  },
  infoIcon: {
    color: theme.palette.common.black
    // color: theme.palette.common.white
  },
  labelChip: {
    backgroundColor: theme.palette.common.neutralNr30,
    color: theme.palette.text.secondary,
    fontSize: '14px',
    padding: '2px 16px 2px 16px',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  labelChipPinang: {
    backgroundColor: theme.palette.common.white,
    fontSize: '9px',
    lineHeight: '16px',
    padding: '0 10px',
    borderRadius: '6px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  backgroundBox: {
    backgroundColor: 'rgba(242, 101, 124, 0.1)',
    marginTop: '16px',
    padding: '8px 14px 8px 14px'
  },
  textBox: {
    color: '#F2657C',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px'
  },
  whiteText: {
    color: theme.palette.common.white
  },
  boxWhite: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderRadius: '4px'
  },
  blueText: {
    color: theme.palette.text.primary
  },
  mainBlueText: {
    color: theme.palette.primary.main
  },

  container: {
    maxWidth: '60em',
    margin: '0 auto'
  },
  progressSegment: {
    display: 'flex'
  },

  item: {
    width: '100%',
    backgroundColor: 'lightgray',
    marginRight: '2px',
    height: '8px',
    borderRadius: '3px'
  },
  itemColor: {
    width: '100%',
    marginRight: '2px',
    height: '8px',
    borderRadius: '3px',
    background: theme.palette.primary.main
  },
  itemColorRed: {
    width: '100%',
    marginRight: '2px',
    height: '8px',
    borderRadius: '3px',
    background: theme.palette.error.main
  },
  autodebetGrid: {
    background: 'rgba(255, 255, 255, 0.1)',
    padding: '4px 12px',
    borderRadius: '10px'
  },
  autodebetTypo: {
    color: 'white'
  },
  rincianGrid: {
    border: '1px solid white',
    borderRadius: '6px'
  },
  rincianTypo: {
    fontWeight: 700,
    color: 'white',
    padding: '0px 12px'
  },
  paidOffButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    color: `${theme.palette.primary.main}`
  },
  installmentInformation: {
    marginTop: '12px',
    marginBottom: '24px'
  },
  lateBox: {
    padding: theme.spacing(1),
    borderRadius: '0px 0px 0px 0px',
    backgroundColor: theme.palette.error.light,
    textAlign: 'center'
  },
  lateBoxWriteOff: {
    padding: theme.spacing(1),
    borderRadius: '0px 0px 12px 12px',
    backgroundColor: theme.palette.error.light,
    textAlign: 'center'
  },
  redBoxWriteOff: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: theme.palette.error.light,
    textAlign: 'center'
  },
  redText: {
    fontFamily: 'Lato',
    fontWeight: 700,
    color: theme.palette.error.main
  },
  confirmButtonWriteOff: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  boxTotal: {
    marginTop: '5px'
  },
  guideList: {
    marginLeft: theme.spacing(2)
  },
  snackbarCopiedStyle: {
    backgroundColor: `${theme.palette.common.stateSuccessRaya}`,
    borderRadius: '8px',
    marginBottom: theme.spacing(8)
  },
  snackbarCopiedStyleMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  snackCopiedText: {
    color: theme.palette.common.white
  },
  copiedIcon: {
    margin: 'auto'
  },
  copiedRemarksIcon: {
    marginLeft: 'auto',
    position: 'relative',
    top: '5px',
    bottom: '5px',
    left: '5px',
    right: '5px'
  },
  copiedBankIcon: {
    marginLeft: theme.spacing(1)
  }
});
